import React, { Component } from 'react';
import NavbarThree from '../components/Layouts/NavbarTwo';
import ContactFormArea from '../components/Contact/ContactFormArea';
import SEO from "../components/Seo"
import Footer from '../components/Layouts/Footer';
import contact from "../assets/images/ContactUs.png"
class Contact extends Component {
    render() {
        return (
            <React.Fragment>
           <SEO title={"Contact Us - Real Estate Developers | Real Estate Development In India"}  keywords={"Real Estate Developers,Credai"} description={"Contact Us CREDAI WARANGAL Address:#5-11-503 &amp; 504/3F/A/T1&amp; 2.3rd Floor Kandakatla Gateway,Hanamkonda,Telangana-506009. +9193906 62255 info@credaiwarangal.org."}  />
                <NavbarThree />
                <div className="page-banner">        

          
       <img src={contact} />
              
    
           

       </div> 
      
                <ContactFormArea />
                <h1>Credai Warangal</h1>
                <div className="responsive-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d485843.1981043323!2d79.392117!3d17.948472!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b43444e2b542d1a!2sCredai%20Warangal!5e0!3m2!1sen!2sus!4v1665198769909!5m2!1sen!2sus" width={1200} height="100%"  allowFullScreen aria-hidden="false" tabIndex={0} />
                </div>
               
                    
                <Footer />
            </React.Fragment>
        );
    }
}

export default Contact;
