import React, { Component } from 'react';
import ContactForm from './ContactForm';


class ContactFormArea extends Component {
    render() {
        return (
            <section className="contact-area ptb-100">
                <div className="container">
                    <div className="contact-inner">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="contact-features-list">
                                    <h1>Please fill this form and we’ll get back to you.</h1 >


                                    <ul>
                                                    <li>
                                            <i className='bx bx-mail-send'></i>
                                            info@credaiwarangal.org
                                        </li>
                                        <li>
            
                                            <i className='bx bx-map'></i>
                                            Location:#5-11-503 & 504/3F/A/T1& 2.,<br></br>
3rd Floor Kandakatla Gateway,Hanamkonda,<br></br>
Telangana - 506009., India.
                                        </li>

                                        <li>
                                            <i className='bx bxs-phone'></i>
                                            +9193906 62255
                                        </li>
                                      
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                           
                                <ContactForm />
                            
                           
                                   </div>
                        </div>

                 
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactFormArea;
